/* Override Angular-Material stuff */

// Strange space over outlined form fields
.mat-form-field-appearance-outline {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    border-top: 0.3em solid transparent;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow {
    margin-top: 10px !important;
  }
}
