/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '../../node_modules/@angular/material/theming';

// Define a custom typography config that overrides the font-family
// or any typography level.
$typography: mat.define-typography-config(
  $font-family: 'Roboto, monospace',
  $headline-5: mat.define-typography-level(32px, 48px, 700)
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($typography);`
@include mat.all-component-typographies($typography);
@include mat.core(); // $typography is an **optional** argument for the mat-core

$primary: mat.define-palette(mat.$light-blue-palette, 800, 100, 900);
$accent: mat.define-palette(mat.$orange-palette, 600, 100, 900);

$warn: mat.define-palette(mat.$red-palette, 600, 100, 900);

$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn
  ),
  density: 0,
  typography: $typography
));

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

@include mat.all-component-themes($theme);

.accession-code {
  font-family: monospace;
}

.flow-cell-id {
  font-family: monospace;
}

.qc-values {
  white-space: pre;
}

$background: #ffffff;
$background-active: #e8f0fe;
$background-active-second: #f5f5f5;
$background-active-laber: #F9E9E7;

$border-color: #dadce0;

$text-color: #5f6368;
$text-color-second: #777777;
$text-active-color: #1967d2;
$text-active-laber-color: #c94031;

$font-family: Roboto, monospace;
$font-bold: 500;
$font-normal: 400;
$font-size-text: 13px;
$font-size-title: 18px;

.invalid {
  border: 2px solid red;
  background-color: #ffe6e6;
}
